import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogIndex from "../components/blogIndex"
import Bio from "../components/bio"
import ContentBlock from "../components/contentBlock"
import Breadcrumbs from "../components/breadcrumbs"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Title from "../components/title"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog - Desarrollo de Aplicaciones, Diseño Web, Ecommerce, Marketing Digital" />
        <ContentBlock theme="violet" angle>
          <BreadcrumbsBar>
            <Breadcrumbs name="FeedYourWeb" page="/" color="#fff" />
            <Breadcrumbs name="Blog" page="/blog" color="#fff" />
          </BreadcrumbsBar>
          <Title className="dark-gray">
            Blog sobre <br />Aplicaciones <br />Diseño Web <br/>Ecommerce <br />y Marketing Digital
          </Title>
        </ContentBlock>
        <ContentBlock>
          <BlogIndex />
          <Bio />
        </ContentBlock>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
